.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: rgb(241, 241, 241);
  border-radius: 20px;
  border: 1px solid transparent;
  background-clip: padding-box;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: rgb(156, 156, 156);
  border-radius: 20px;
  border: 1px solid transparent;
  background-clip: padding-box;
}

.custom-scrollbar::-webkit-scrollbar-button {
  display: none;
}
